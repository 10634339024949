<template>
  <main class="no-main">
    <section class="section--single-post ps-page--business">
      <div class="ps-breadcrumb">
        <div class="container-fluid-sy">
          <ul class="ps-breadcrumb__list">
            <li class="active">
              <router-link :to="{ name: 'home' }">Home</router-link>
            </li>
            <li class="active">
              <router-link :to="{ name: 'dashboard' }">Myaccount</router-link>
            </li>
            <li><a href="javascript:void(0);">Sender Address</a></li>
          </ul>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12 mt-4">
            <h3 class="checkout__title">Sender Details</h3>

            <form @submit.prevent="onSubmit()">
              <div class="row">
                
                <div class="col-12 col-lg-4 p-0">
                  <div class="col-12 col-lg-12 form-group--block">
                    <label>First name: <span>*</span></label>
                    <input
                      class="form-control"
                      type="text"
                      required
                      placeholder="Enter the first name"
                      v-model="formDate.first_name"
                    />
                  </div>
                </div>

                <div class="col-12 col-lg-4 p-0">
                  <div class="col-12 col-lg-12 form-group--block">
                    <label>Last name: <span>*</span></label>
                    <input
                      class="form-control"
                      type="text"
                      required
                      placeholder="Enter the last name"
                      v-model="formDate.last_name"
                    />
                  </div>
                </div>

                <div class="col-12 col-lg-4 p-0">
                  <div class="col-12 col-lg-12 form-group--block">
                    <label>Phone: <span>*</span></label>
                    <div class="input-group"
                        :class="{ 'is-invalid': $errors.has('phonecode') || $errors.has('phone') }"
                    >
                      <select class="form-control"
                        :class="{ 'is-invalid': $errors.has('phonecode') }"
                        v-model="formDate.phonecode" >
                        <option value="" disabled>Select</option>
                        <option v-for="(country, index) in countriesWithPhonecode" :key="index" :value="country.phonecode">
                          ({{ country.phonecode }}) {{ country.name }}
                        </option>
                      </select>

                      <input
                        class="form-control"
                        :class="{ 'is-invalid': $errors.has('phone') }"
                        type="text"
                        placeholder="Enter the phone number"
                        v-model="formDate.phone"
                      />
                    </div>
                    <div
                      class="invalid-feedback"
                      v-show="$errors.has('phonecode')"
                    >
                      {{ $errors.first("phonecode") }}
                    </div>
                    <div
                      class="invalid-feedback"
                      v-show="$errors.has('phone')"
                    >
                      {{ $errors.first("phone") }}
                    </div>
                  </div>
                </div>
                
                <div class="col-12 col-lg-4 p-0">
                  <div class="col-12 col-lg-12 form-group--block">
                    <label>Country: <span>*</span></label>
                    <select class="form-control drobdown" name="country" v-model="formDate.country">
                      <option value="">Select a country</option>
                      <option :value="country.code" v-for="(country, index) in countries" :key="index">
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-12 col-lg-4 p-0">
                  <div class="col-12 col-lg-12 form-group--block">
                    <label>State: <span>*</span></label>
                    <select class="form-control drobdown" name="state" v-model="formDate.state" v-if="states.length">
                      <option value="">Select a state</option>
                      <option :value="state.code" v-for="(state, index) in states" :key="index">
                        {{ state.default_name }}
                      </option>
                    </select>
                    <input
                      type="text"
                      name="state"
                      class="form-control"
                      placeholder="Enter the state"
                      v-model="formDate.state"
                      v-else
                    />
                  </div>
                </div>

                <div class="col-12 col-lg-4 p-0">
                  <div class="col-12 col-lg-12 form-group--block">
                    <label>Address: <span>*</span></label>
                    <input
                      class="form-control"
                      type="text"
                      required
                      placeholder="Enter the address"
                      v-model="formDate.address1[0]"
                    />
                  </div>
                </div>
              </div>

              <div class="row">  

                <div class="col-12 col-lg-4 p-0">
                  <div class="col-12 col-lg-12 form-group--block">
                    <label>City: <span>*</span></label>
                    <input
                      class="form-control"
                      type="text"
                      required
                      placeholder="Enter the city"
                      v-model="formDate.city"
                    />
                  </div>
                </div>             

                <div class="col-12 col-lg-4 p-0">
                  <div class="col-12 col-lg-12 form-group--block">
                    <label>PostCode /Zip(Optional):</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter the postcode"
                      v-model="formDate.postcode"
                    />
                  </div>
                </div>               
              </div>

              <div class="row ml-0 mb-3">
                <div class="input-group-append mt-4">
                  <button type="submite" class="btn btn-lg btn-success save-btn">
                    {{ editMode ? "Update" : "Save" }}
                  </button>
                </div>
                <div class="input-group-append mt-4 ml-2">
                  <router-link class="btn btn-lg btn-danger save-btn" :to="{ name: 'address' }">
                    Cancel
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      formDate: {
        address1: [],
        first_name:"",
        last_name:"",
        country: "",
        state: "",
        city: "",
        postcode: "",
        phonecode: '',
        phone: "",
      },
    };
  },

  beforeMount() {
    if (this.countries && !this.countries.length) {
      this.fetchCountries({ sort: "name", order: "asc" });
    }

    this.fetchStates();
  },

  mounted() {
    if (this.$route.params) {
      if (this.$route.params.id) {
        this.fetchAddresse(this.$route.params.id);
      }
    }
    this.fetchCountriesWithPhonecode();
  },

  computed: {
    ...mapGetters("country", ["countries", "statesByCountry", "countriesWithPhonecode"]),
    ...mapGetters("address", ["address"]),

    states() {
      return this.statesByCountry(this.formDate.country);
    },

    editMode() {
      return !!this.formDate.id;
    },
  },

  watch: {
    address(newVal) {
      this.formDate = Object.assign(this.formDate, newVal);
    },
  },

  methods: {
    ...mapActions("country", ["fetchCountries", "fetchStates", "fetchCountriesWithPhonecode"]),
    ...mapActions("address", ["fetchAddresse", "addAddress", "editAddress"]),

    onSubmit() {
      this.formDate.category = "sender";
      if (this.editMode) {
        this.editAddress(this.formDate).then(() => {
          this.$router.push({ name: "address" });
        });
      } else {
        this.addAddress(this.formDate).then(() => {
          this.$router.push({ name: "address" });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.save-btn {
  width: 70px;
  height: 35px;
}
</style>
